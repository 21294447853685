<template>
    <section>
        <div class="row mx-0 bg-white br-8 py-3 my-3">
            <div class="col d-middle pl-5 text-general f-600 tres-puntos f-18">
                <p class="tres-puntos">
                    Entregas por franjas horarias
                </p>
            </div>
            <div class="col-auto d-middle">
                <div class="px-1 text-general lh-18">
                    Ver Cedis y Delivery <br /> asignado del pedido
                </div>
                <div>
                    <el-switch v-model="value1" active-color="#000000" />
                </div>
            </div>
            <div class="col-xl-3 col-lg d-flex">
                <div class="d-flex align-items-end px-1">
                    <img v-if="leecheroImagen" :src="leecheroImagen" width="38" height="38" class="obj-cover rounded-circle" />
                    <div v-else class="bg-general2 rounded-circle d-middle-center" style="width:38px;height:38px;">
                        <i class="icon-leechero-cap text-white f-20" />
                    </div>
                </div>
                <div class="w-100">
                    <!-- <p class="text-general f-14 pl-3">
                        Leechero
                    </p> -->
                    <el-select v-model="leechero" placeholder="Seleccione" class="w-100" filterable size="small" clearable @change="filtrarPorLeechero()">
                        <el-option
                        v-for="(item, i) in leecheros"
                        :key="i"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div class="col-xl-3 col-lg d-flex">
                <div class="d-flex align-items-end px-1">
                    <img v-if="deliveryImagen" :src="deliveryImagen" width="38" height="38" class="obj-cover rounded-circle" />
                    <div v-else class="bg-general2 rounded-circle d-middle-center" style="width:38px;height:38px;">
                        <i class="icon-moped text-white f-25" />
                    </div>
                </div>
                <div class="w-100">
                    <!-- <p class="text-general f-14 pl-3">
                        Delivery
                    </p> -->
                    <el-select v-model="delivery" placeholder="Seleccione" class="w-100" size="small" filterable clearable @change="filtrarPorDelivery()">
                        <el-option
                        v-for="(item, i) in deliveries"
                        :key="i"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3 py-2 bg-white br-t-8">
            <div class="col-auto d-middle pl-5 tres-puntos">
                <p class="text-general tres-puntos f-18 f-600">
                    Filtra por <br /> estado
                </p>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="sin_confirmar" class="mx-2 check-yellow" />
                <div :class="sin_confirmar? 'bg-yellow text-white' : 'bg-light-f5 border text-general2'" class="  px-2 br-5 tres-puntos cr-pointer height-box" @click="sin_confirmar = !sin_confirmar">
                    <i class="icon-receipt f-20" />
                    <span class="f-15">
                        Sin Confirmar ({{ contarPorEstado(1) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="confirmado" class="mx-2 check-blue" />
                <div :class="confirmado ? 'bg-blue text-white' : 'bg-light-f5 border text-general2'" class="  px-2 br-5 tres-puntos cr-pointer height-box" @click="confirmado = !confirmado">
                    <i class="icon-ok-circled f-20" />
                    <span class="f-15">
                        Confirmado ({{ contarPorEstado(2) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="alistando" class="mx-2 check-pink" />
                <div :class="alistando ? 'bg-pink text-white' : 'bg-light-f5 border text-general2'" class=" px-2 br-5 tres-puntos cr-pointer height-box" @click="alistando = !alistando">
                    <i class="icon-cart-arrow-down f-20" />
                    <span class="f-15">
                        Alistando ({{ contarPorEstado(201) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="empacado" class="mx-2 check-purple" />
                <div :class="empacado ? 'bg-purple text-white' : 'bg-light-f5 border text-general2' " class="  px-2 br-5 tres-puntos cr-pointer height-box" @click="empacado = !empacado">
                    <i class="icon-shopping-outline f-20" />
                    <span class="f-15">
                        Empacado ({{ contarPorEstado(202) }})
                    </span>
                </div>
            </div>
            <div class="col px-0 d-middle">
                <el-checkbox v-model="enviado" class="mx-2 check-green" />
                <div :class="enviado? 'bg-green text-white' : 'bg-light-f5 border text-general2'" class=" px-2 br-5 tres-puntos cr-pointer height-box" @click="enviado = !enviado">
                    <i class="icon-truck f-20" />
                    <span class="f-15">
                        Enviado ({{ contarPorEstado(3) }})
                    </span>
                </div>
            </div>
        </div>
        <div class="custom-scroll_tablero pt-2 bg-white overflow-auto" style="height:calc(100vh - 310px)">
            <component :is="tabla" :pedidos="datosFiltradosPorEstado" :ver-cedis="value1" @listar="getData()" />
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'

import tablero from '~/services/pedidos/tablero'

const filtrado = (datos, todosCedis = false, cb) => {
    let datosCopy = _.cloneDeep(datos)
    return datosCopy.filter(el => {
        if(!el.horarios.length) return true
        const p = el.horarios.filter(elm => {
            if(!elm.pedidos.length) return true
            const j = elm.pedidos.filter(ele => {
                return cb(ele)
            })
            elm.pedidos = _.orderBy(j, 'id')
            return elm.pedidos.length > 0
        })
        el.horarios = todosCedis ? p : el.horarios
        return el.horarios.length > 0
    })
}


export default {
    name: 'Tablero',
    components: {
        columnLate: () => import('./components/columnLate'),
        column : () => import('./components/column')
    },
    data(){
        return {
            value1: false,
            sin_confirmar: true,
            confirmado: true,
            alistando: true,
            empacado: true,
            enviado: true,
            datos: [],
            estados: [],
            datosFiltradosPorEstado: [],
            leecheros: [],
            deliveries: [],
            leechero: '',
            delivery: '',
        }
    },
    sockets: {
        tablero(pedido){
            this.updateEstadoPedido(pedido)
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            cedisNavbar:'cedis/cedisNavbar',
        }),
        deliveryImagen(){
            const { imagen } = this.deliveries.find(el => el.id == this.delivery) ?? {}
            return imagen
        },
        leecheroImagen(){
            const { foto: imagen } = this.leecheros.find(el => el.id == this.leechero) ?? {}
            return imagen
        },
        tabla(){
            return this.multipleCedis ? 'columnLate' : 'column'
        },
        multipleCedis(){
            return this.cedisNavbar.length == 0 || this.cedisNavbar.length > 1
        }
    },
    watch: {
        async id_cedis(val){
            await this.getData()
            this.setEstados()
            this.filtrarPorEstado()

        },
    },
    async mounted(){
        this.$watch(vm => [vm.sin_confirmar, vm.confirmado, vm.alistando, vm.empacado, vm.enviado], val => {
            this.setEstados()
            this.filtrarPorEstado()
        })
        await this.getData()
        this.setEstados()
        this.filtrarPorEstado()
    },
    methods: {
        async getData(){
            try {
                const { data } = this.multipleCedis ? await tablero.getData() : await tablero.porCedis();
                this.datos = data.pedidos
                this.leecheros = data.leecheros
                this.deliveries = data.deliveries
            } catch (error){
                this.error_catch(error)
            }
        },
        setEstados(){
            const estados = []
            this.sin_confirmar ? estados.push('1') : null
            this.confirmado ? estados.push('2') : null
            this.alistando ? estados.push('201') : null
            this.empacado ? estados.push('202') : null
            this.enviado ? estados.push('3') : null
            this.estados = estados
        },
        contarPorEstado(estado){
            let count = 0
            if(!this.datosFiltradosPorEstado.length) return count
            this.datosFiltradosPorEstado.forEach(el => {
                el.horarios.forEach(elm => {
                    const pedidos = elm.pedidos.filter(ped => ped.estado == estado).length
                    count += pedidos
                })
            })
            return count
        },
        filtrarPorEstado(){
            this.datosFiltradosPorEstado = filtrado(this.datos, this.multipleCedis, (el) => {
                return this.estados.includes(el.estado.toString()) &&
                (!this.leechero ? true : el.id_tendero == this.leechero) &&
                (!this.delivery ? true : el.id_delivery == this.delivery)
            })
        },
        filtrarPorLeechero(){
            if(!this.leechero) return this.filtrarPorEstado()
            this.filtrarPorEstado()
            this.datosFiltradosPorEstado = filtrado(this.datosFiltradosPorEstado, this.multipleCedis, (el) => {
                return el.id_tendero == this.leechero
            })
        },
        filtrarPorDelivery(){
            if(!this.delivery) return this.filtrarPorEstado()
            this.filtrarPorEstado()
            this.datosFiltradosPorEstado = filtrado(this.datosFiltradosPorEstado, this.multipleCedis, (el) => {
                return el.id_delivery == this.delivery
            })
        },
        async updateEstadoPedido(pedido){
            const { data } = JSON.parse(pedido)
            if (data.tipo == 1){
                this.datos.forEach(el => {
                    el.horarios.forEach(elm => {
                        elm.pedidos.forEach(ele => {
                            if (ele.id == data.id){
                                ele.estado = data.estado
                            }
                        })
                    })
                })
            } else if (data.tipo == 2){
                await this.savePedido(data.id)
            } else if (data.tipo == 3){
                this.quitarPedido(data.id)
            }
            this.filtrarPorEstado()
        },
        quitarPedido(idPedido){
            this.datos = filtrado(this.datos, this.multipleCedis, el => {
                return el.id != idPedido
            })
        },
        async savePedido(idPedido){
            const { data } = await tablero.buscarPedido(idPedido)

            this.quitarPedido(data.id)
            if(moment(data.entrega_fecha).isAfter(moment().add(2, 'days').format('YYYY-MM-DD'))) return


            let indexF = this.datos.findIndex(el => el.fecha == data.entrega_fecha)
            if (indexF == -1){
                let toSave = {
                    fecha: data.entrega_fecha,
                    horarios: [{
                        horario: data.entrega_horario,
                        pedidos: [ data ]
                    }]
                }
                this.datos.push(toSave)
                this.datos.sort((a, b) => {
                    var dateA = new Date(a.fecha);
                    var dateB = new Date(b.fecha);
                    return dateA - dateB;
                });
            } else {
                let indexH = this.datos[indexF].horarios.findIndex(el => el.horario == data.entrega_horario)
                if (indexH == -1){
                    const horarios = this.datos[indexF].horarios
                    this.datos[indexF].horarios = [...horarios, {
                        horario: data.entrega_horario,
                        pedidos: [ data ]
                    }]
                } else {
                    this.datos[indexF].horarios[indexH].pedidos.push(data)
                    _.orderBy(this.datos[indexF].horarios[indexH].pedidos, 'id')
                }
            }

            if (data.id_delivery){
                this.buscarDeliveries()
            }
            if(!this.leecheros.includes(data.id_tendero)){

            }
        },
        async buscarDeliveries(){
            try {
                const params = {
                    cedis: this.id_cedis
                }
                const { data } = await tablero.buscarDeliveries(params);
                this.deliveries = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async buscarLeecheros(){
            try {
                const params = {
                    cedis: this.id_cedis
                }
                const { data } = await tablero.buscarLeecheros(params);
                this.leecheros = data
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-yellow{ background-color: #FF9D32; }
.text-yellow{ color: #FF9D32; }
.bg-blue{ background-color: #0892DD; }
.text-blue{ color: #0892DD;}
.bg-pink{ background-color: #D6017B; }
.text-pink{ color: #D6017B;}
.bg-purple{ background-color: #6D3CE1; }
.text-purple{ color: #6D3CE1; }
.bg-green{ background-color: #28D07B;}
.text-green{ color: #28D07B; }
.custom-scroll_tablero::-webkit-scrollbar-track{
    height: 6px;
    width:12px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1); background-color:#F5F5F5; border-radius: 5px; }

.custom-scroll_tablero::-webkit-scrollbar{
    background-color: #DBDBDB;
    width:8px;
    height: 18px;
    border-radius: 5px;
}
.custom-scroll_tablero::-webkit-scrollbar-thumb{
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
     background:var(--text-general);
     border-radius: 5px;
}
.height-box{
    height: 40px;
    align-items: center;
    display: flex;
}
</style>
